@import './assets/styles/hangry-constants.module';

@import '@hangry/react-ui-kit/dist/utils.module';

:root {
  --status-bar-height: 0px;
  --hui-bottomsheet-max-width: #{$pageMaxWidth};

  --ui-kit-design-width: #{strip-unit($designMobileWidth)};
  --ui-kit-scale-max-width: #{strip-unit($designMobileWidth)};
}

.App {
  box-sizing: border-box;
  max-width: $pageMaxWidth;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.touchable-opacity {
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.touchable-opacity:active {
  opacity: 0.7;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}

.loading-loadable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ios-gap-padding {
  padding-bottom: 24px !important;
}

.ios-gap-margin {
  margin-bottom: 24px !important;
}
