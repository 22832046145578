// Local Project
$pageMaxWidth: 500px;

// Mobile
$designMobileWidth: 360px; // screen width used on design to scale into

:export {
  pageMaxWidth: $pageMaxWidth;
  designMobileWidth: $designMobileWidth;
}
