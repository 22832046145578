@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toaster{position:fixed;z-index:99;max-width:500px;top:min(48 / var(--ui-kit-design-width) * 100vw,
    48px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );box-sizing:border-box;width:100%;padding:0 min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );opacity:0;transition:.4s}.Toaster .toast-content-container{background-color:var(--errorDefault);box-shadow:0 min(1 / var(--ui-kit-design-width) * 100vw,
    1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(10 / var(--ui-kit-design-width) * 100vw,
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) rgba(0,0,0,.05);border-radius:min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );width:100%;box-sizing:border-box;overflow:hidden;display:flex;justify-content:space-between;align-items:center}.Toaster .toast-content-container .toast-content{display:flex;justify-content:space-between;align-items:center;background-color:var(--neutralPrimary);width:100%;padding:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  ) min(10 / var(--ui-kit-design-width) * 100vw,
    10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );flex:1 1}.Toaster .toast-content-container .toast-content .toast-message{color:#000;margin-right:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:Inter,sans-serif;font-weight:500;font-size:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(18 / var(--ui-kit-design-width) * 100vw,
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );color:var(--errorDefault)}.Toaster .toast-content-container .exclamation-icon-container{width:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );display:flex;justify-content:center;align-items:center;padding:min(8 / var(--ui-kit-design-width) * 100vw,
    8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.Toaster .toast-content-container .exclamation-icon-container .exclamation-icon{font-size:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );color:var(--neutralPrimary)}.Toaster .toast-content-container .icon-container{width:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );display:flex;justify-content:center;align-items:center;cursor:pointer}.Toaster .toast-content-container .icon-container .close-icon{color:var(--textDisabled);font-size:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.show{opacity:1}
.loader{display:flex}.loader div{width:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(12 / var(--ui-kit-design-width) * 100vw,
    12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );border-radius:50%;margin:min(2 / var(--ui-kit-design-width) * 100vw,
    2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );background-color:var(--textPrimary);animation-name:blink;animation-duration:1.4s;animation-iteration-count:infinite;animation-fill-mode:both}.loader div:nth-child(2){animation-delay:.2s}.loader div:nth-child(3){animation-delay:.4s}@keyframes blink{0%{transform:scale(0)}50%{transform:scale(1)}100%{transform:scale(0)}}
.ErrorPage{height:100vh;display:flex;flex-direction:column}.ErrorPage .error-content{flex:1 1;display:flex;flex-direction:column;align-items:center;justify-content:center}.ErrorPage .error-content .error-image{width:min(280 / var(--ui-kit-design-width) * 100vw,
    280px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );height:min(160 / var(--ui-kit-design-width) * 100vw,
    160px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.ErrorPage .error-content .error-title{margin-top:min(24 / var(--ui-kit-design-width) * 100vw,
    24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:Inter,sans-serif;font-weight:700;font-size:min(18 / var(--ui-kit-design-width) * 100vw,
    18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(26 / var(--ui-kit-design-width) * 100vw,
    26px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );color:var(--textPrimary)}.ErrorPage .error-content .error-description{max-width:min(328 / var(--ui-kit-design-width) * 100vw,
    328px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-top:min(4 / var(--ui-kit-design-width) * 100vw,
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );font-family:Inter,sans-serif;font-weight:400;font-size:min(14 / var(--ui-kit-design-width) * 100vw,
    14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );line-height:min(20 / var(--ui-kit-design-width) * 100vw,
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );color:var(--textSecondary);text-align:center}.ErrorPage .error-content .button-container{max-width:min(360 / var(--ui-kit-design-width) * 100vw,
    360px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );padding:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );margin-top:min(4 / var(--ui-kit-design-width) * 100vw,
    4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  );display:flex}.ErrorPage .error-content .button-container .button-ui-kit{width:min(156 / var(--ui-kit-design-width) * 100vw,
    156px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}.ErrorPage .error-content .button-container .button-ui-kit:last-child{margin-left:min(16 / var(--ui-kit-design-width) * 100vw,
    16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )}
:root{--status-bar-height: 0px;--hui-bottomsheet-max-width: 500px;--ui-kit-design-width: 360;--ui-kit-scale-max-width: 360}.App{box-sizing:border-box;max-width:500px;height:100%;margin:0 auto;display:flex;flex-direction:column}.touchable-opacity{cursor:pointer;outline:none;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:rgba(0,0,0,0)}.touchable-opacity:active{opacity:.7}.disable-select{user-select:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none}.loading-loadable{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.ios-gap-padding{padding-bottom:24px !important}.ios-gap-margin{margin-bottom:24px !important}
